import React from 'react';
import HomeBanner from '../HomeBanner/HomeBanner';
import TestimonialCarousel from '../TestimonialCarousel/TestimonialCarousel';
import HomeHighlightCards from '../HomeHighlightCards/HomeHighlightCards';
import HomeServicesSection from '../HomeServicesSection/HomeServicesSection';
import { BusinessNeedPanel } from '../BusinessPanels/BusinessPanels';
import ContactBanner from '../ContactBanner/ContactBanner';
import ContactHighlightCards from '../ContactHighlightCards/ContactHighlightCards';
import GoogleMapEmbed from '../GoogleMapEmbed/GoogleMapEmbed';
import ScrollUp from '../ReusableComponents/ScrollUp/ScrollUp';
import upicon from './upicon.svg';
import styles from './HomePage.module.css';
import ContentContainer from '../ReusableComponents/ContentContainer/ContentContainer';

const HomePage = () => {
  return (
    <div data-testid="HomePage">
      <ScrollUp>
        <img className={styles.upIcon} src={upicon} alt="scroll up" />
      </ScrollUp>
      <HomeBanner />
      <HomeHighlightCards />
      <ContentContainer className={styles.panelWrapper} width={100}>
        <BusinessNeedPanel title="Flexible solutions to meet your needs.">
          Our software solutions are designed to be flexible and adaptable, so
          that they can meet your specific needs and grow with your business. We
          understand that every business is unique, which is why we work closely
          with our clients to understand their requirements and create
          customized solutions that are tailored to their needs. Whether you
          need a software system that can integrate with your existing
          infrastructure, accommodate new workflows, or support multiple user
          interfaces, our flexible solutions provide the freedom and agility to
          achieve your business objectives. With our focus on flexibility and
          scalability, we help businesses stay competitive, reduce costs, and
          improve efficiency and productivity. Contact us today to learn more
          about our flexible software solutions.
        </BusinessNeedPanel>
        <HomeServicesSection />
      </ContentContainer>
      <ContentContainer className={styles.bannerWrapper} width={100}>
        <TestimonialCarousel />
        <ContactBanner />
      </ContentContainer>
      <ContentContainer className={styles.directionWrapper} width={100}>
      <ContactHighlightCards />
      <GoogleMapEmbed />
      </ContentContainer>
    </div>
  );
};
export default HomePage;
