import React, { useContext } from 'react';
import ContentContainer from '../ReusableComponents/ContentContainer/ContentContainer';
import HeroSection from '../ReusableComponents/HeroSection/HeroSection';
import ContactForm from '../ContactForm/ContactForm';
import styles from './ContactPage.module.css';
import bannerImage from './bannerImage.png';
import ThemeContext from '../../contexts/ThemeContext';

const ContactBanner = () => {
  const { primaryFont, secondaryFont } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };
  return (
    <ContentContainer>
      <div className={styles.bannerWrapper}>
        <div className={styles.header} id="contact">
          <h4 style={primaryFontStyle}>Get in Touch with Us</h4>
          <p style={secondaryFontStyle}>
            Have a question but aren't sure who to contact? Fill in the form to
            get in touch and a member of our team will reach out to you at the
            earliest
          </p>
        </div>
        <HeroSection
          contentWidthPercent={50}
          contentSection={<ContactForm />}
          imageSection={
            <img
              className={styles.BannerImage}
              src={bannerImage}
              alt="banner"
            />
          }
        />
      </div>
    </ContentContainer>
  );
};

export default ContactBanner;
