import React from 'react';
import PropTypes from 'prop-types';
import styles from './HighlightCard.module.css';
import PanelSection from "../PanelSection/PanelSection"

const HighlightCard = (props) => {
  const { panelProps, url, aligned, image, title, description, className } = props;

  const handleRedirect = () => {
    if (!url) return;
    window.location.assign(url);
  };

  const cardClasses = `${styles.HighlightCard} ${url ? styles.isLink : ''} ${className}`;

  return (
    <PanelSection {...panelProps} className={cardClasses}>
      <div
        data-testid="highlight-card"
        style={{ textAlign: aligned }}
        className={styles.cardContent}
        onClick={handleRedirect}
      >
        <div className={styles.cardImage}>{image}</div>
        <h4 className={styles.cardTitle}>{title}</h4>
        <p className={styles.cardDescription}>{description}</p>
      </div>
    </PanelSection>
  );
};

const panelPropsShape = PropTypes.shape({
  backgroundColor: PropTypes.string,
  shadow: PropTypes.bool,
  shadowColor: PropTypes.string,
});
HighlightCard.propTypes = {
  image: PropTypes.element.isRequired,
  aligned: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
  panelProps: panelPropsShape,
};

HighlightCard.defaultProps = {
  image: undefined,
  aligned: 'center',
  title: undefined,
  description: undefined,
  url: undefined,
  className:'',
  panelProps: {
    backgroundColor: undefined,
    shadow: false,
    shadowColor: undefined,
  },
};
export default HighlightCard;
