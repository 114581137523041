import React from 'react';
import ContentContainer from '../ReusableComponents/ContentContainer/ContentContainer';
import HeroSection from '../ReusableComponents/HeroSection/HeroSection';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import BusinessImage from './business.png';
import styles from './HomeBanner.module.css';

const HomeBanner = () => {
  const { blackColor,  whiteColor, primaryFont, secondaryFont } =
    useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  const getStartedLinkProps = {
    style: {
      ...secondaryFontStyle,
      color: blackColor,
      backgroundColor: whiteColor,
      border: 'none',
    },
    to: 'https://calendly.com/evolvebay/30min',
    target: '_blank',
  };

  return (
    <div data-testid="HomeBanner"  className={styles.HomeBanner}>
      <ContentContainer>
        <div className={styles.BannerWrapper}>
          <HeroSection
            contentWidthPercent={43}
            contentSection={
              <>
                <div
                  className={styles.InfoContainer}
                  style={{ color: whiteColor }}
                >
                  <h1 style={primaryFontStyle}>
                    Where imagination meets innovation
                  </h1>
                  <div className={styles.ButtonContainer}>
                    <Link {...getStartedLinkProps}>Book an appointment</Link>
                  </div>
                </div>
              </>
            }
            imageSection={
              <>
                <img src={BusinessImage} alt="business" />
              </>
            }
          />
        </div>
      </ContentContainer>
    </div>
  );
};

export default HomeBanner;
