import React, { useContext } from 'react';
import HighlightCard from "../ReusableComponents/HighlightCard/HighlightCard"
import styles from './ContactHighlightCards.module.css';
import ThemeContext from '../../contexts/ThemeContext';
import { CallIcon, MailIcon, LocationIcon } from './CardsIconsSvg';

const ContactHighlightCards = () => {
  const {
    primaryFont,
    secondaryFont,
    primaryColor,
    phoneNumber,
    email,
    address,
    whiteColor
  } = useContext(ThemeContext);
  const panelProps = {
    backgroundColor: primaryColor,
    shadow: false,
  };
  const primaryFontStyle = {
    fontFamily: primaryFont,
    color: whiteColor
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
    color: whiteColor
  };

  const cardsData = [
    {
      title: 'Call Us',
      description: phoneNumber,
      image: <CallIcon backgroundColor={whiteColor} />,
    },
    {
      title: 'Message Us',
      description: email,
      image: <MailIcon backgroundColor={whiteColor} />,
    },
    {
      title: 'Visit Us',
      description: address,
      image: <LocationIcon backgroundColor={whiteColor} />,
    },
  ];

  return (
    <div className={styles.cardWrapper} data-testid="ContactHighlightCards">
      {cardsData.map((card, index) => (
        <HighlightCard
          key={index}
          panelProps={panelProps}
          className={styles.card}
          image={card.image}
          aligned="left"
          title={<span style={primaryFontStyle}>{card.title}</span>}
          description={
            <span style={secondaryFontStyle}>{card.description}</span>
          }
        />
      ))}
    </div>
  );
};

export default ContactHighlightCards;
