import React, { useContext } from 'react';
import styles from './TemplateFooter.module.css';
import ContentContainer from '../ReusableComponents/ContentContainer/ContentContainer';
import PanelSection from '../ReusableComponents/PanelSection/PanelSection';
import BusinessLogo from '../ReusableComponents/BusinessLogo/BusinessLogo';
import ThemeContext from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import { BrowserRoutes } from '../../constants/BrowserRoutes';
import {
  CallIcon,
  FacebookIcon,
  LinkedinIcon,
  MailIcon,
} from './FooterIconsSvg';

const TemplateFooter = () => {
  const {
    blackColor,
    coloredLogo,
    primaryColor,
    // businessName,
    phoneNumber,
    email,
    secondaryFont,
    primaryFont,
    socialMediaLinks,
  } = useContext(ThemeContext);

  const { linkedin, facebook } = socialMediaLinks;

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const textStyle = {
    color: blackColor,
    fontFamily: secondaryFont,
  };

  const navLinkStyle = {
    ...textStyle,
    cursor: 'pointer',
  };

  const panelProps = {
    className: styles.FooterPanel,
    backgroundColor: '#c1c1c129',
    shadow: false,
  };
  const scroll = (id) => {
    window.scrollTo({
      top: document.getElementById(id).offsetTop,
      behavior: 'smooth',
    });
  };
  return (
    <div className={styles.TemplateFooter} data-testid="TemplateFooter">
      <PanelSection {...panelProps}>
        <ContentContainer>
          <div className={styles.FooterNavLinks} style={textStyle}>
            <div className={styles.FooterNavColumn}>
              <h4 style={primaryFontStyle}>Get in touch</h4>
              <ul>
                <li>
                  <Link style={navLinkStyle} to={`tel:${phoneNumber}`}>
                    <CallIcon backgroundColor={primaryColor} /> {phoneNumber}
                  </Link>
                </li>
                <li>
                  <Link style={navLinkStyle} to={`mailto:${email}`}>
                    <MailIcon backgroundColor={primaryColor} /> {email}
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.FooterNavColumn}>
              <h4 style={primaryFontStyle}>Navigation</h4>
              <ul>
                <li>
                  <span
                    style={navLinkStyle}
                    to={BrowserRoutes.HOME}
                    onClick={() => scroll('home')}
                  >
                    Home
                  </span>
                </li>
                <li>
                  <span
                    style={navLinkStyle}
                    to={BrowserRoutes.HOME}
                    onClick={() => scroll(BrowserRoutes.TESTIMONIALS)}
                  >
                    Testimonials
                  </span>
                </li>
                <li>
                  <span
                    style={navLinkStyle}
                    to={BrowserRoutes.HOME}
                    onClick={() => scroll(BrowserRoutes.CONTACT)}
                  >
                    Contact Us
                  </span>
                </li>
                <li>
                  <span
                    style={navLinkStyle}
                    to={BrowserRoutes.HOME}
                    onClick={() => scroll(BrowserRoutes.SERVICES)}
                  >
                    Services
                  </span>
                </li>
              </ul>
            </div>

            <div className={styles.FooterNavColumn}>
              <h4 style={primaryFontStyle}>Follow Us</h4>
              <ul className={styles.socialIcons}>
                <li>
                  <Link target={'_blank'} style={navLinkStyle} to={linkedin}>
                    <LinkedinIcon backgroundColor={primaryColor} />
                  </Link>
                </li>
                <li>
                  <Link target={'_blank'} style={navLinkStyle} to={facebook}>
                    <FacebookIcon backgroundColor={primaryColor} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.FooterBranding}>
            {/* <div style={textStyle}>© {businessName}</div> */}
            <div
              className={styles.FooterLogoContainer}
              onClick={() => scroll('home')}
            >
              <Link className={styles.FooterLogo} to={BrowserRoutes.HOME}>
                <BusinessLogo img={coloredLogo} size={25} />
              </Link>
            </div>
            {/* <div style={textStyle}>
              <Link to={BrowserRoutes.PRIVACY_POLICY} style={textStyle}>
                Privacy Policy
              </Link>{' '}
              |{' '}
              <Link to={BrowserRoutes.TERMS_OF_SERVICE} style={textStyle}>
                Terms of Service
              </Link>
            </div> */}
          </div>
        </ContentContainer>
      </PanelSection>
    </div>
  );
};

export default TemplateFooter;
