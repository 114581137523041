import React, { useState, useContext } from 'react';
import styles from './ContactForm.module.css';
import ThemeContext from '../../contexts/ThemeContext';

const initialFormState = {
  name: '',
  contact: '',
  email: '',
  message: '',
};

const FormInput = ({ value, name, type, placeholder, onChange }) => {
  const { testimonialColors } = useContext(ThemeContext);
  return (
    <div
      className={styles.inputContainer}
      style={{ borderColor: testimonialColors.cardImageBorderColor }}
    >
      <input
        data-testid={name}
        required
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const ContactForm = () => {
  const {
    secondaryFont,
    secondaryColor,
    blackColor,
    whiteColor,
    testimonialColors,
  } = useContext(ThemeContext);

  const [formData, setFormData] = useState(initialFormState);
  const { name, contact, email, message } = formData;

  const handleValueChange = (event) => {
    let input = event.target;
    setFormData({ ...formData, [input.name]: input.value });
  };


  const secondaryFontStyle = {
    fontFamily: secondaryFont,
    color: blackColor,
  };
  const buttonStyle = {
    color: whiteColor,
    backgroundColor: secondaryColor,
  };

  return (
    <div className={styles.contactForm} data-testid="Contact-From">
      <form
        name="contact-form"
        method="post"
        data-testid="ContactFormElement"
        style={secondaryFontStyle}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <div className={styles.formInputs}>
          <FormInput
            label="Name"
            value={name}
            name={'name'}
            type="text"
            placeholder="Name"
            onChange={handleValueChange}
          />
          <FormInput
            label="Phone"
            value={contact}
            name={'contact'}
            type="text"
            placeholder="Phone"
            onChange={handleValueChange}
          />
        </div>
        <div className={styles.emailInput}>
          <div className={styles.formInputs}>
            <FormInput
              label="Email"
              value={email}
              name={'email'}
              type="email"
              placeholder="Email"
              onChange={handleValueChange}
            />
          </div>
        </div>
        <div className={styles.formInputs}>
          <div
            className={styles.inputTextArea}
            style={{ borderColor: testimonialColors.cardImageBorderColor }}
          >
            <textarea
              data-testid="message"
              required
              style={secondaryFontStyle}
              name="message"
              placeholder="How can we help you?"
              rows={10}
              value={message}
              onChange={handleValueChange}
            />
            <button type="submit"  style={buttonStyle}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
