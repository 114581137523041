import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from "../ReusableComponents/ContentContainer/ContentContainer"
import HighlightCard from "../ReusableComponents/HighlightCard/HighlightCard"
import PanelSection from "../ReusableComponents/PanelSection/PanelSection"
import styles from './BusinessPanels.module.css';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import checkIcon from './checkIcon.png';
import image from "./image.png"

export const BusinessNeedPanel = (props) => {
  const { whiteColor, primaryFont, secondaryFont, secondaryColor } =
    useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  return (
    <ContentContainer>
      <PanelSection
        className={styles.panel1}
        backgroundColor={secondaryColor}
      >
        <div>
          <img src={image} alt="personSitting"/>
        </div>
        <div
          data-testid="BusinessNeedPanel"
          style={{ color: whiteColor }}
          className={styles.PanelContent}
        >
          <h4 className={styles.PanelHeading} style={primaryFontStyle}>
            {props.title}
          </h4>
          <p style={secondaryFontStyle}>{props.children}</p>
        </div>
      </PanelSection>
    </ContentContainer>
  );
};

BusinessNeedPanel.propTypes = {
  title: PropTypes.string.isRequired,
};

BusinessNeedPanel.defaultProps = {
  title: undefined,
};

export const BusinessNeedOptionsPanel = () => {
  const { whiteColor, blackColor, primaryColor, primaryFont, secondaryFont } =
    useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  return (
    <ContentContainer>
      <PanelSection
        className={styles.panel}
        backgroundColor={primaryColor}
        shadow={false}
      >
        <div
          data-testid="BusinessNeedOptionsPanel"
          style={{ color: whiteColor }}
          className={styles.PanelContent}
        >
          <h4 className={styles.PanelHeading} style={primaryFontStyle}>
            Your business needs options
          </h4>
          <div style={{ color: blackColor }} className={styles.PanelCards}>
            {[0, 1, 2, 3, 4, 5].map((idx) => (
              <HighlightCard
                key={idx}
                className={styles.card}
                image={<img src={checkIcon} alt="options" />}
                aligned="left"
                title={<span style={primaryFontStyle}>Quality</span>}
                description={
                  <span style={secondaryFontStyle}>
                    Lorem Ipsum is simply dummy text of the printing and type
                    setting.
                  </span>
                }
              />
            ))}
          </div>
        </div>
      </PanelSection>
    </ContentContainer>
  );
};
