import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScrollUp.module.css';

const ScrollUp = (props) => {
  const scrolltop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };
  const classes = [styles.ScrollUp, props.className, "hide"];
  return (
    <div
      id="scroll-icon"
      onClick={scrolltop}
      className={classes.join(' ')}
      data-testid="ScrollUp"
    >
      {props.children}
    </div>
  );
};
ScrollUp.propTypes = {
  className: PropTypes.string,
};

ScrollUp.defaultProps = {
  className: '',
};
export default ScrollUp;
