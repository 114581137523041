import React, { useContext } from 'react';
import styles from './GoogleMapEmbed.module.css';
import ContentContainer from '../ReusableComponents/ContentContainer/ContentContainer';
import PanelSection from '../ReusableComponents/PanelSection/PanelSection';
import ThemeContext from '../../contexts/ThemeContext';
import walkImage from './walk.png';
import HeroSection from '../ReusableComponents/HeroSection/HeroSection';

const GoogleMapEmbed = () => {
  const { primaryFont, secondaryFont } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  return (
    <div className={styles.GoogleMapEmbed} data-testid={'GoogleMapEmbed'}>
      <ContentContainer>
        <div className={styles.header}>
          <h4 style={primaryFontStyle}>Directions</h4>
          <p style={secondaryFontStyle}>
            Navigate your way to success with our interactive map, guiding you
            towards new opportunities, exciting destinations, and limitless
            possibilities.
          </p>
        </div>
        <PanelSection className={styles.mapContainer}>
          <HeroSection
            contentSection={
              <div className={styles.imageContainer}>
                <img src={walkImage} alt="Location" />
              </div>
            }
            imageSection={
              <iframe
                title="Google-Map-Embed"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212.66058614518778!2d74.31093435486052!3d31.48102989734961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903f97554e00f%3A0xb02d8f15dc2e80b4!2sEvolveBay!5e0!3m2!1sen!2s!4v1688378824860!5m2!1sen!2s"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe>
            }
          />
          <div style={{ display: 'flex', gap: '1em' }}></div>
        </PanelSection>
      </ContentContainer>
    </div>
  );
};

export default GoogleMapEmbed;
