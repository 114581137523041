import React from 'react';
import PropTypes from 'prop-types';
import styles from './ImageLinkCard.module.css';
import LoadingImage from '../LoadingImage/LoadingImage';

const Arrow = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="1327.001 2657.002 51.999 51.998"
    fill={fill}
    data-testid="image-link-card-icon"
  >
    <path
      d="M1352.998 2709a25.81 25.81 0 0 1-10.12-2.045 25.824 25.824 0 0 1-8.263-5.572 25.879 25.879 0 0 1-5.572-8.264 25.852 25.852 0 0 1-2.042-10.121 25.917 25.917 0 0 1 7.614-18.383 25.927 25.927 0 0 1 18.383-7.613 25.94 25.94 0 0 1 18.386 7.613 25.918 25.918 0 0 1 7.616 18.383 25.918 25.918 0 0 1-7.616 18.385 25.866 25.866 0 0 1-8.265 5.572 25.816 25.816 0 0 1-10.121 2.045Zm-3.523-33.496a.81.81 0 0 0-.58.24l-.495.496a.827.827 0 0 0 0 1.164l5.628 5.625-5.633 5.639a.83.83 0 0 0 0 1.166l.49.494c.152.152.366.24.585.24a.81.81 0 0 0 .58-.24l6.71-6.709a.835.835 0 0 0 0-1.17l-6.7-6.705a.815.815 0 0 0-.585-.24Z"
      fillRule="evenodd"
    />
  </svg>
);

const ImageLinkCard = (props) => {
  const { title, url, image, className, titleColor } = props;
  const classes = [styles.ImageLinkCard, className];
  return (
    <div className={classes.join(' ')} data-testid="ImageLinkCard">
      <a href={url} target="_blank" rel="noreferrer">
        <LoadingImage image={image} altText={title} />
        <div className={styles.title}>
          <p style={{ color: titleColor }}>{title}</p>
          <Arrow fill={titleColor} />
        </div>
      </a>
    </div>
  );
};

ImageLinkCard.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};

ImageLinkCard.defaultProps = {
  titleColor: 'white',
  title: 'untitled',
  image: '',
  url: '#',
  className: '',
};

export default ImageLinkCard;
