import React from 'react';
import PropTypes from 'prop-types';
import styles from './TestimonialCard.module.css';
import PanelSection from '../PanelSection/PanelSection';

const TestimonialCard = (props) => {
  const { panelProps, profile, colors, className } = props;
  const classes = [styles.TestimonialCard, className];
  return (
    <PanelSection {...panelProps} className={classes.join(' ')}>
      <div data-testid="testimonial-card" className={styles.cardContent}>
        <div className={styles.comment} data-testid="testimonial-comment">
          {props.children}
        </div>
        <span
          className={styles.divider}
          style={{ backgroundColor: colors.dividerColor }}
        ></span>
        {profile.profileElement ? (
          profile.profileElement
        ) : (
          <div className={styles.profile}>
            <div
              className={styles.imageContainer}
              style={{ borderColor: colors.borderColor }}
            >
              <img src={profile.img} alt={profile.profileName} />
            </div>

            <div className={styles.profileDetails}>
              <p>
                <em>{profile.profileName}</em>
              </p>
              <p>{profile.profileTitle}</p>
            </div>
          </div>
        )}
      </div>
    </PanelSection>
  );
};

const panelPropsShape = PropTypes.shape({
  backgroundColor: PropTypes.string,
  shadow: PropTypes.bool,
  shadowColor: PropTypes.string,
});
const profilePropsShape = PropTypes.shape({
  profileElement: PropTypes.element,
  profileName: PropTypes.string,
  profileTitle: PropTypes.string,
  img: PropTypes.string,
});

TestimonialCard.propTypes = {
  profile: profilePropsShape,
  panelProps: panelPropsShape,
  colors: PropTypes.shape({
    dividerColor: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  className: PropTypes.string,
};

TestimonialCard.defaultProps = {
  profile: {
    profileElement: undefined,
    profileName: '',
    profileTitle: '',
    img: '',
  },
  colors: {
    dividerColor: 'black',
    borderColor: 'black',
  },
  panelProps: {
    backgroundColor: undefined,
    shadow: false,
    shadowColor: undefined,
  },
  className: '',
};
export default TestimonialCard;
