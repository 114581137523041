import React from 'react';
import ContentContainer from "../ReusableComponents/ContentContainer/ContentContainer"
import HighlightCard from "../ReusableComponents/HighlightCard/HighlightCard"
import styles from './HomeHighlightCards.module.css';
import card1 from './card1.png';
import card2 from './card2.png';
import card3 from './card3.png';

const cardsData = [
  {
    image: <img src={card1} alt="card1" />,
  },
  {
    image: <img src={card2} alt="card2" />,
  },
  {
    image: <img src={card3} alt="card3" />,
  },
];

const HomeHighlightCards = () => {
  const panelProps = {
    shadow: true,
  };

  return (
    <ContentContainer width={65}>
      <div
        data-testid="HomeHighlightCards"
        className={styles.HomeHighlightCards}
      >
        {cardsData.map((card, index) => (
          <HighlightCard
            key={index}
            image={card.image}
            panelProps={panelProps}
            className={styles.highLightCard}
          />
        ))}
      </div>
    </ContentContainer>
  );
};

export default HomeHighlightCards;
