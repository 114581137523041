import React from 'react';
import ContentContainer from "../ReusableComponents/ContentContainer/ContentContainer"
import ImageLinkCard from "../ReusableComponents/ImageLinkCard/ImageLinkCard"
import styles from './HomeServicesSection.module.css';
import card1 from './card1.png';
import card2 from './card2.png';
import card3 from './card3.png';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';

const cardsData = [
  {
    image: card1,
    title: 'Web Apps',
  },
  {
    image: card2,
    title: 'Mobile Apps',
  },
  {
    image: card3,
    title: 'Software Development',
  },
];

const HomeServicesSection = () => {
  const { primaryFont, secondaryFont } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  return (
    <ContentContainer>
      <div
        id="services"
        data-testid="HomeServicesSection"
        className={styles.HomeServicesSection}
      >
        <div className={styles.header}>
          <h4 style={primaryFontStyle}>Our Services</h4>
          <p style={secondaryFontStyle}>
            We provide the best available choices for you. Adjust these
            according to your needs and leave the rest to us! Our expertise in
            infrastructure-as-code can help you replicate your products
            architecture without any manual labour.
          </p>
        </div>
        <div className={styles.cards} style={primaryFontStyle}>
          {cardsData.map((card, index) => (
            <ImageLinkCard
              key={index}
              {...card}
              className={styles.imageCards}
            />
          ))}
        </div>
      </div>
    </ContentContainer>
  );
};

export default HomeServicesSection;
