import React from 'react';
export const DoubleQuotesIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.887"
    height="29.859"
    viewBox="760.999 4467.359 42.887 39.859"
  >
    <path
      d="M785.42 4505.457v-7.152c-.001-.97.786-1.758 1.757-1.757 3.463 0 5.346-3.551 5.608-10.561h-5.609c-.97 0-1.757-.787-1.757-1.758v-15.111c0-.97.787-1.758 1.757-1.757h14.95c.971 0 1.758.786 1.759 1.757v15.109a38.813 38.813 0 0 1-1.006 9.166 22.05 22.05 0 0 1-3.12 7.254 15.23 15.23 0 0 1-5.303 4.845 15.094 15.094 0 0 1-7.281 1.724 1.758 1.758 0 0 1-1.756-1.759Zm-22.664-8.913c-.97 0-1.757.786-1.757 1.757v7.158c0 .97.787 1.755 1.757 1.755a15.09 15.09 0 0 0 7.28-1.723 15.206 15.206 0 0 0 5.302-4.845 22.029 22.029 0 0 0 3.12-7.256 38.792 38.792 0 0 0 1.005-9.168v-15.106c0-.97-.787-1.757-1.757-1.757h-14.95c-.97 0-1.757.787-1.757 1.757v15.106c0 .97.786 1.758 1.757 1.758h5.529c-.258 7.013-2.115 10.564-5.53 10.564Z"
      fill={fill}
      fillRule="evenodd"
      data-name="Caminho 15610"
    />
  </svg>
);

export const NextArrowIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="55"
    viewBox="1776 4649.359 64.998 64.999"
  >
    <path
      d="M1808.497 4714.357c-4.387 0-8.643-.859-12.648-2.553a32.387 32.387 0 0 1-10.331-6.965 32.404 32.404 0 0 1-6.964-10.332 32.3 32.3 0 0 1-2.554-12.65 32.3 32.3 0 0 1 2.554-12.65 32.388 32.388 0 0 1 6.964-10.33 32.4 32.4 0 0 1 22.98-9.518 32.395 32.395 0 0 1 22.982 9.519 32.407 32.407 0 0 1 9.518 22.978 32.412 32.412 0 0 1-9.519 22.982 32.387 32.387 0 0 1-10.33 6.966 32.307 32.307 0 0 1-12.652 2.553Zm-2.998-40.993a.914.914 0 0 0-.66.273l-.562.558a.928.928 0 0 0-.273.66c0 .25.097.484.273.66l6.388 6.382-6.394 6.395a.935.935 0 0 0 0 1.321l.561.562a.94.94 0 0 0 1.32 0l7.615-7.614a.954.954 0 0 0 0-1.328l-7.608-7.596a.923.923 0 0 0-.66-.273Z"
      fill={fill}
      fillRule="evenodd"
      data-name="Subtraction 88"
    />
  </svg>
);
export const BackArrowIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="55"
    viewBox="80 4649.359 64.998 64.999"
  >
    <path
      d="M112.497 4714.357c-4.387 0-8.643-.859-12.648-2.553a32.387 32.387 0 0 1-10.331-6.965 32.404 32.404 0 0 1-6.964-10.332 32.3 32.3 0 0 1-2.554-12.65 32.3 32.3 0 0 1 2.554-12.65 32.388 32.388 0 0 1 6.964-10.33 32.4 32.4 0 0 1 22.98-9.518 32.395 32.395 0 0 1 22.982 9.519 32.407 32.407 0 0 1 9.518 22.978 32.412 32.412 0 0 1-9.519 22.982 32.387 32.387 0 0 1-10.33 6.966 32.307 32.307 0 0 1-12.652 2.553Zm3-40.994a.933.933 0 0 0-.661.27l-7.604 7.604a.94.94 0 0 0 0 1.324l7.61 7.614a.937.937 0 0 0 1.323 0l.56-.562a.935.935 0 0 0 0-1.32l-6.392-6.396 6.385-6.381a.927.927 0 0 0 .273-.66.928.928 0 0 0-.273-.661l-.558-.562a.944.944 0 0 0-.664-.27Z"
      fill={fill}
      fillRule="evenodd"
      data-name="Subtraction 89"
    />
  </svg>
);
