import React from 'react';
import styles from './NotFoundPage.module.css';
import ContentContainer from "../ReusableComponents/ContentContainer/ContentContainer"
import PanelSection from "../ReusableComponents/PanelSection/PanelSection"

const NotFoundPage = () => (
  <ContentContainer>
    <div className={styles.NotFoundPage} data-testid="NotFoundPage">
      <PanelSection shadow={false}>
        <div className={styles.notFound}>404 Not Found</div>
      </PanelSection>
    </div>
  </ContentContainer>
);

export default NotFoundPage;
