import TemplateRoutes from "./components/TemplateRoutes/TemplateRoutes";
import ThemeContext, { themeDefaults } from "./contexts/ThemeContext";
import { ToastContainer } from 'react-toastify';

const themeSettings = {
  ...themeDefaults,
  // primaryColor: 'orange',
  // secondaryColor: 'blue',
  // etc. etc. etc.
}

function App() {
  return (
    <div className="App">
      <ToastContainer />
          <ThemeContext.Provider value={themeSettings}>
            <TemplateRoutes />
          </ThemeContext.Provider>
    </div>
  );
}

export default App;
