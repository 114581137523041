export const BrowserRoutes = {
  HOME: '/',
  TESTIMONIALS: 'testimonials',
  ABOUT: '/about',
  SERVICES: 'services',
  CONTACT: 'contact',
  PRIVACY_POLICY: '/privacy',
  TERMS_OF_SERVICE: '/terms-of-service',
  NOTFOUND: '*'
};
