import React, { useState } from 'react';
import styles from './TemplateHeader.module.css';

import { NavLink, useLocation } from 'react-router-dom';
import { BrowserRoutes } from '../../constants/BrowserRoutes';

import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import MobileNavButton from '../MobileNavButton/MobileNavButton';
import ContentContainer from '../ReusableComponents/ContentContainer/ContentContainer';
import BusinessLogo from '../ReusableComponents/BusinessLogo/BusinessLogo';

const StyledNavLink = (props) => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const color =
    pathname === BrowserRoutes.HOME ? theme.whiteColor : theme.blackColor;

  const textStyle = {
    color,
    borderColor: color,
    fontFamily: props.fontFamily ?? theme.secondaryFont,
  };
  const scroll = (id) => {
    window.scrollTo({
      top: document.getElementById(id).offsetTop,
      behavior: 'smooth',
    });
  };
  return (
    <NavLink style={textStyle} className={props.className ?? ''} to={'/'} onClick={()=>scroll(props.to)}>
      {props.children}
    </NavLink>
  );
};

const NavItems = () => {
  const theme = useContext(ThemeContext);

  const buttonStyle = {
    backgroundColor: theme.whiteColor,
    color: theme.blackColor,
    fontFamily: theme.secondaryFont,
  };

  return (
    <>
      <ul>
        <li>
          <StyledNavLink to={BrowserRoutes.HOME}>Home</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to={BrowserRoutes.TESTIMONIALS}>
            Testimonials
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to={BrowserRoutes.SERVICES}>Services</StyledNavLink>
        </li>
      </ul>
      <ul>
        <StyledNavLink to={BrowserRoutes.CONTACT}>
          <button style={buttonStyle}>Contact Us</button>
        </StyledNavLink>
      </ul>
    </>
  );
};

const TemplateHeader = () => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const backgroundColor = pathname === BrowserRoutes.HOME ? theme.secondaryColor : 'white';
  const logo =
    pathname === BrowserRoutes.HOME ? theme.whiteLogo : theme.coloredLogo;

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  const classes = `${styles.MobileNav} ${open ? '' : styles.isHidden}`;

  return (
    <div id="home" style={{ backgroundColor }}>
      <ContentContainer width={90}>
        <nav className={styles.Nav} data-testid="TemplateHeader">
          <StyledNavLink
            to={BrowserRoutes.HOME}
            className={styles.logo}
            fontFamily={theme.primaryFont}
          >
            <BusinessLogo img={logo} size={50} />
            {theme.headerTitle}
          </StyledNavLink>
          <div className={styles.DesktopNav}>
            <NavItems />
          </div>
          <MobileNavButton open={open} handleToggle={handleToggle} />
        </nav>
      </ContentContainer>
      <div
        style={{ backgroundColor }}
        data-testid="MobileNavPanel"
        className={classes}
        onClick={(e) => {
          if (e.target !== e.currentTarget) {
            handleToggle();
          }
        }}
      >
        <NavItems />
      </div>
    </div>
  );
};

export default TemplateHeader;
