import React from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
// import AboutPage from '../AboutPage/AboutPage';
import HomePage from '../HomePage/HomePage';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TemplateLayout from '../TemplateLayout/TemplateLayout';
import { BrowserRoutes } from '../../constants/BrowserRoutes';
// import ServicePage from '../ServicePage/ServicePage';
// import ContactPage from '../ContactPage/ContactPage';

const routerConfig = createBrowserRouter([
  {
    path: BrowserRoutes.HOME,
    element: <TemplateLayout />,
    children: [
      {
        path: BrowserRoutes.HOME,
        element: <HomePage />
      },
      // {
      //   path: BrowserRoutes.ABOUT,
      //   element: <AboutPage />
      // },
      // {
      //   path: BrowserRoutes.SERVICES,
      //   element: <ServicePage />
      // },
      // {
      //   path: BrowserRoutes.CONTACT,
      //   element: <ContactPage />
      // },
      {
        path: BrowserRoutes.NOTFOUND,
        element: <NotFoundPage />
      }
    ]
  },
])

const TemplateRoutes = () => (
  <RouterProvider router={routerConfig} />
);

export default TemplateRoutes;
