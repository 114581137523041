import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loadingGif from './loading.gif';
import emptyImage from './empty.png';

const LoadingImage = (props) => {
  const {
    image,
    styleClass,
    altText,
    titleText,
    onClickFunc,
    loadingImage,
    placeholderImage,
  } = props;

  const [imageLoading, setImageLoading] = useState(true);
  const [renderedImage, setRenderedImage] = useState(image);

  return (
    <React.Fragment>
      <img
        data-testid="ImageLoading"
        className={styleClass}
        src={loadingImage}
        hidden={!imageLoading}
        alt="Loading..."
      />
      <img
        data-testid="ImageRendered"
        className={styleClass}
        onLoad={() => {
          setImageLoading(false);
        }}
        onError={() => {
          setRenderedImage(placeholderImage);
          setImageLoading(false);
        }}
        hidden={imageLoading}
        src={renderedImage || placeholderImage}
        alt={altText}
        title={titleText}
        onClick={onClickFunc}
      />
    </React.Fragment>
  );
};

LoadingImage.propTypes = {
  loadingImage: PropTypes.string,
  placeholderImage: PropTypes.string,
  image: PropTypes.string,
  styleClass: PropTypes.string,
  altText: PropTypes.string.isRequired,
  titleText: PropTypes.string,
  onClickFunc: PropTypes.func,
};

LoadingImage.defaultProps = {
  loadingImage: loadingGif,
  placeholderImage: emptyImage,
  image: undefined,
  styleClass: '',
  altText: undefined,
  titleText: '',
  onClickFunc: () => {},
};

export default LoadingImage;
