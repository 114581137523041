import React, { useState } from 'react';
import testimonials from '../../constants/testimonials.json';
import ContentContainer from "../ReusableComponents/ContentContainer/ContentContainer"
import TestimonialCard from "../ReusableComponents/TestimonialCard/TestimonialCard"
import styles from './TestimonialCarousel.module.css';
import { NextArrowIcon, BackArrowIcon } from './SvgIcons';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import BusinessLogo from '../ReusableComponents/BusinessLogo/BusinessLogo';

const Carousel = ({ itemsPerPage, testId }) => {
  const { testimonialColors, primaryFont, secondaryFont, blackColor } = useContext(ThemeContext);

  const primaryFontStyle = {
    fontFamily: primaryFont,
  };

  const secondaryFontStyle = {
    fontFamily: secondaryFont,
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const panelProps = {
    backgroundColor: testimonialColors.cardBackgroundColor,
    shadow: true,
  };
  const cardColors = {
    borderColor: testimonialColors.cardImageBorderColor,
    dividerColor: testimonialColors.cardDividerColor,
  };

  const calculateNewIndexForPrevClick = (
    currentIndex,
    maxItemsPerPage,
    totalItemCount
  ) => {
    // Calculate the index for the previous set of items
    const newIndex = currentIndex - maxItemsPerPage;
    // If the newIndex is within the valid range, return it
    if (newIndex >= 0) {
      return newIndex;
    }
    // Otherwise:
    // Find out how many sets of items exist with the current values
    const totalSets = Math.ceil(totalItemCount / maxItemsPerPage);
    // Calculate the index of the last set so we end up with indexes like:
    // 0, 3, 6, 9, 12 in the case of 3 items per page.
    const lastIndex = (totalSets - 1) * maxItemsPerPage;
    return lastIndex;
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      calculateNewIndexForPrevClick(
        prevIndex,
        itemsPerPage,
        testimonials.length
      )
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => {
      // Calculate the new index for the next set of items
      const newIndex = prevIndex + itemsPerPage;

      // If the new index is equal to or greater than the array length, wrap around to the start of the array
      return newIndex >= testimonials.length ? 0 : newIndex;
    });
  };

  const renderTestimonials = () => {
    const startIndex = activeIndex;
    const endIndex = startIndex + itemsPerPage;
    const slicedTestimonials = testimonials.slice(startIndex, endIndex);

    return slicedTestimonials.map((testimonial, index) => (
      <TestimonialCard
        key={index}
        className={styles.cardBottom}
        panelProps={panelProps}
        colors={cardColors}
        profile={{
          profileName: testimonial.profileName,
          profileTitle: testimonial.profileTitle,
          img: testimonial.img,
        }}
      >
        <BusinessLogo className={styles.businessImage} img={testimonial.companyImage} size={100}/>
        <p
          style={{color: blackColor}}
          data-testid={`comment-testimonial-${testId}`}
          className={styles.TestimonialComment}
        >
          {testimonial.comment}
        </p>
      </TestimonialCard>
    ));
  };

  return (
    <div className={styles.TestimonialCarouselContainer}>
      <ContentContainer>
        <center>
          <h4
            style={primaryFontStyle}
            data-testid={`carousel-heading-${testId}`}
            className={styles.CarouselHeading}
          >
            What Customers Say About Us
          </h4>
        </center>
        <div className={styles.TestimonialCarousel}>
          <button
            onClick={handlePrevClick}
            data-testid={`prev-button-${testId}`}
          >
            <BackArrowIcon fill={testimonialColors.carouselNavColor} />
          </button>
          <div style={secondaryFontStyle} className={styles.TestimonialContainer}>
            {renderTestimonials()}
          </div>
          <button
            data-testid={`next-button-${testId}`}
            onClick={handleNextClick}
          >
            <NextArrowIcon fill={testimonialColors.carouselNavColor} />
          </button>
        </div>
        <div className={styles.TestimonialCarouselMobile}>
          <button onClick={handlePrevClick}>
            <BackArrowIcon fill={testimonialColors.carouselNavColor} />
          </button>

          <button onClick={handleNextClick}>
            <NextArrowIcon fill={testimonialColors.carouselNavColor} />
          </button>
        </div>
      </ContentContainer>
    </div>
  );
};

const TestimonialCarousel = () => {
  return (
    <div id="testimonials">
      <div className={styles.desktopCarousel}>
        <Carousel itemsPerPage={3} testId="desktop" />
      </div>
      <div className={styles.laptopCarousel}>
        <Carousel itemsPerPage={2} testId="laptop" />
      </div>
      <div className={styles.tabletCarousel}>
        <Carousel itemsPerPage={1} testId="tablet" />
      </div>
    </div>
  );
};

export default TestimonialCarousel;
